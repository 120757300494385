import Cookies from 'js-cookie';
import { StateCache } from '@/services/cache';
// import jsrsasign from 'jsrsasign';
import { showAuthError } from '@/composables/Alert/useAlert';

const cache = new StateCache();

export default {
    install: async () => {
        const authToken = Cookies.get('curbside_token');
        if (!authToken) {
            showAuthError();
            return;
        }

        const tokenVal = JSON.parse(atob(authToken.split('.')[1]));

        // Make sure the token hasn't expired
        if (tokenVal.exp < Math.floor(Date.now() / 1000)) {
            showAuthError();
            return;
        }

        await Promise.all([
            cache.set(cache.AUTH_TOKEN, authToken),
            cache.set(cache.SHOP_ID, tokenVal.shop_id),
        ]);
    },
};
