import Rollbar from 'rollbar';
import configs from '@/services/config';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $rollbar: Rollbar;
    }
}

// export interface ComponentCustomProperties {}

export default {
    install: (app: any) => {
        // disable rollbar for local env
        if (process.env.NODE_ENV == 'development') return;

        // disable all environments for now
        return;

        // Set the Rollbar instance in the Vue prototype
        // before creating the first Vue instance.
        // This ensures it is available in the same way for every
        // instance in your app.
        app.config.globalProperties.$rollbar = new Rollbar({
            accessToken: configs().rollbarAccessToken(),
            captureUncaught: true,
            captureUnhandledRejections: true,
            enabled: true,
            environment: process.env.NODE_ENV,
            payload: {
                client: {
                    javascript: {
                        // eslint-disable-next-line
                        source_map_enabled: true,
                        // Optionally have Rollbar guess which frames the error was
                        // thrown from when the browser does not provide line
                        // and column numbers.
                        // eslint-disable-next-line
                        guess_uncaught_frames: true
                    },
                },
            },
        });
        app.config.errorHandler = (err: any, vm: any, /* info: any */) => {
            vm.$rollbar.error(err);
            throw err; // rethrow
        };
    },
};
