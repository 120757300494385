import { XhrClient } from './XhrClient';
import useConfig from '@/services/config';

export class ApiService extends XhrClient {
    /**
     * GET: /shops
     * GET: /shops/${shopID}/${method}
     * Get shop(s)
     * @param  {string} id [Leave empty to get all shops]
     * @return {Promise<ApiRes.ShopI>}
     */
    public async getShop(id?: number | string): Promise<ApiRes.ShopI> {
        const shopID: number = await this.state.get(this.state.SHOP_ID);
        if (!shopID && !id) Promise.reject();

        return this.get(`/shops/${id ?? shopID}`);
    }

    public async getShops(): Promise<ApiRes.ShopsI> {
        return this.get(`/shops`);
    }

    public getFirebaseToken(): Promise<ApiRes.FirebaseTokenI> {
        return this.get(`/curbside/firebase-token`);
    }
    public postCurbsideSetStatus(enable: boolean) {
        return this.post(`/curbside/set-status`, { enable });
    }
    public async postPauseStatus(status: string, employee_id: string, type: string, reason: string) {
        const shopID: number = await this.state.get(this.state.SHOP_ID);
        return this.post(
            `/shops/${shopID}/set-status`,
            {
                status,
                type,
                employee_id,
                reason
            }
        );
    }
    /**
     * Get an array of messages in the current conversation
     */
    public async getConfigs() {
        return this.get(`/settings/config`)
            .catch(() => {
                return [];
            });
    }

    public loyaltySignupGuest(shopID: number, offer_slug: string,  params: any) {
        return this.post(`/shops/${shopID}/loyalty-signup/${offer_slug}`, params);
    }

    protected async getheaders(
        headerOverwrite: { [key: string]: any } = {}
    ): Promise<ApiHeadersI> {
        const authToken: string = await this.state.get(this.state.AUTH_TOKEN);
        const activeShopID: string = await this.state.get(this.state.SHOP_ID);

        const headerOptions: ApiHeadersI = {
            'Api-Token': useConfig().apiToken(),
            'X-Client': this.getXhrHeaderClientKey(),
        };

        if (window.location && window.location.href) {
            headerOptions['X-Referrer'] = window.location.href;
        }

        if (authToken) {
            headerOptions['Authorization'] = `Bearer ${authToken}`;
        }

        if (activeShopID || headerOverwrite['X-Active-shop']) {
            // Header values have to be strings
            headerOptions['X-Active-shop'] = `${headerOverwrite[
                'X-Active-shop'
            ] || activeShopID}`;
        }
        return headerOptions;
    }
}

interface ApiHeadersI {
    'Api-Token': string;
    'X-Client': string;
    'X-Referrer'?: string;
    'X-Active-shop'?: string;
    Authorization?: string;
}
