
import { defineComponent, inject, toRefs } from 'vue';
import { Store } from '@/stores';
import {
    IonMenu,
    IonToolbar,
    IonIcon,
    IonButton,
    menuController,
} from '@ionic/vue';

export default defineComponent({
    name: 'Navigation',
    components: {
        IonMenu,
        IonToolbar,
        IonIcon,
        IonButton,
    },
    setup(): object {
        const store = inject('store') as Store;
        const { shopName } = toRefs(store.state);
        const { isPaused } = toRefs(store.pauseShopData);

        return {
            isPaused,
            shopName,
            menuController
        };
    },
    data: () => ({
        navItems: [
            {label: 'PICKUP ORDERS', target: '/orders'},
            // {label: 'LOYALTY', target: '/loyalty/signup'}, // Removed from PIZZA-2261
            {label: 'LOYALTY GUEST', target: '/loyalty/guest'},        ]
    }),
    methods: {
        pauseStateText(isPaused: boolean){
            return isPaused ? 'Resume Orders' : 'Pause Orders';
        },
        togglePauseState(){
            console.log('togglePauseState');
        },
        goTo(target: string) {
            this.$router.push(target);
            menuController.close();
        }
    },
});
