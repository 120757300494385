import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        redirect: '/orders',
    },
    {
        path: '/orders',
        component: () => import('@/pages/Curbside.vue'),
        children: [
            {
                path: '',
                name: 'Orders',
                component: () => import('@/pages/curbside/Orders.vue'),
            },
            {
                path: '/orders/queued',
                name: 'Queued Orders',
                component: () => import('@/pages/curbside/OrdersQueued.vue'),
            },
            {
                path: '/orders/:id',
                name: 'OrderDetail',
                component: () => import('@/pages/curbside/OrderDetail.vue'),
            },
            {
                path: '/orders/notifications',
                name: 'Notifications',
                component: () => import('@/pages/curbside/Notifications.vue'),
            },
        ],
    },
    {
        path: '/loyalty',
        component: () => import('@/pages/Loyalty.vue'),
        children: [
            /*{
                path: '',
                redirect: '/loyalty/signup',
            },
            {
                path: '/loyalty/signup',
                name: 'LoyaltySignup',
                component: () => import('@/pages/loyalty/Signup.vue'),
            },
            {
                path: '/loyalty/signup/confirmation',
                name: 'LoyaltySignupConfirmation',
                component: () => import('@/pages/loyalty/SignupConfirmation.vue'),
            },*/
            {
                path: '/loyalty/guest/',
                name: 'SignUpGuestForm',
                component: () => import('@/pages/loyalty/SignUpGuestForm.vue'),
            },
            {
                path: '/loyalty/guest/signup',
                name: 'LoyaltySignupGuest',
                component: () => import('@/pages/loyalty/SignupGuest.vue'),
            },           
            {
                path: '/loyalty/guest/confirmation',
                name: 'LoyaltyGuestConfirmation',
                component: () => import('@/pages/loyalty/SignupGuestConfirmation.vue'),
            },
            
        ]
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
