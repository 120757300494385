import { ApiService } from '@/services/api';
import { provide, reactive, readonly } from 'vue';

export interface Store {
    state: StoreStateI;
    pauseShopData: PauseShopDataI;
    postPauseShopStatus: (type: string, reason: string, otherReason: string, employeeID: string) => any;
    toggleCurbside: () => void;
    toggleShowHqModal: () => boolean;
}
export interface StoreStateI {
    currentTime: Date;
    showCurbside: boolean;
    showPickup: boolean;
    validClientPlatform: string[];
    showHqModal: boolean;
    showOrderGuestMessage: boolean;
    shopName: string;
    shopID: number | undefined;
}

export interface PauseShopDataI {
    activePauseTypes: string[];
    isPaused: boolean;
    pauseBlurb: string[];
    pauseOptions: string[];
    pauseReasons: string[];
}

const client = new ApiService();

export const createStore = (): void => {
    /**
     * Initialize the state object
     */
    const state: StoreStateI = reactive({
        currentTime: new Date(),
        shopName: '',
        shopID: undefined,
        showCurbside: false,
        showPickup: false,
        validClientPlatform: [],
        showHqModal: false,
        showOrderGuestMessage: false,
    });

    const pauseShopData: PauseShopDataI = reactive({
        activePauseTypes: [],
        isPaused: false,
        pauseBlurb: [],
        pauseOptions: [],
        pauseReasons: [],
    })


    /**
     * sync has_(curbside|smart_pickup) with api
     * Only update values if they differ,
     * that is important since these values changes affects UI components and want to avoif rerenders
     */
    const syncShopData = async (existingShop?: any) => {
        const shop = existingShop ? existingShop : await client.getShop();

        state.showPickup = true; // Always allow pickup
        // if (state.showPickup != shop.data?.pickup_options?.has_smartpickup) {
        //     state.showPickup = shop.data?.pickup_options?.has_smartpickup;
        // }

        if (state.shopName != shop.data?.name) {
            state.shopName = shop.data?.name;
        }

        if (state.shopID != shop.data?.id) {
            state.shopID = shop.data?.id;
        }

        if (state.showCurbside != shop.data?.pickup_options?.has_curbside) {
            state.showCurbside = shop.data?.pickup_options?.has_curbside;
        }

        if (state.showOrderGuestMessage != shop.data.route_guest_message_to_shop) {
            state.showOrderGuestMessage = shop.data?.route_guest_message_to_shop ? shop.data?.route_guest_message_to_shop : false;
        }

        if (pauseShopData.activePauseTypes != shop.data?.service_schedule?.current?.active_pause_types) {
            pauseShopData.activePauseTypes = shop.data?.service_schedule?.current?.active_pause_types ?? [];
            pauseShopData.isPaused = Boolean(pauseShopData.activePauseTypes?.length);
        }

        if (pauseShopData.pauseBlurb != shop.data?.service_schedule?.current?.pause_blurb) {
            pauseShopData.pauseBlurb = shop.data?.service_schedule?.current?.pause_blurb ?? [];
        }

        if (pauseShopData.pauseOptions != shop.data?.service_schedule?.current?.pause_options) {
            pauseShopData.pauseOptions = shop.data?.service_schedule?.current?.pause_options ?? [];
        }

    };

    // if (this._validClientPlatform) return this._validClientPlatform;
    // this._validClientPlatform = (await )?
    client.getConfigs().then((configs)=>{
        state.validClientPlatform = configs.data?.curbside?.tablet?.valid_client_platforms;
        pauseShopData.pauseReasons = configs.data?.copy_translations['shop.pause_reasons'];
    })


    /**
     * Toggle the state of the curbside pickup table
     *
     * @returns void
     */
    const toggleCurbside = (val: CustomEvent): void => {
        if (val.detail.checked == state.showCurbside) return;
        state.showCurbside = val.detail.checked;
        client.postCurbsideSetStatus(state.showCurbside);
    };

    /**
     * Posts to set the pause shop status - formatting data to post + handling errors
     */
    const postPauseShopStatus = (type: string, reason: string, otherReason: string, employeeID: string): any => {
        const status = pauseShopData.isPaused ? "enable" : "pause";
        const statusData = {
            status,
            type,
            employeeID,
            reason
        }
        if (reason == 'other') {
            statusData.reason = otherReason;
        }
        if (status == 'enable') {
            statusData.type = '';
            statusData.reason = '';
        }

        return client.postPauseStatus(statusData.status, statusData.employeeID, statusData.type, statusData.reason)
            .then((response) => {
                syncShopData(response);
            })
            .catch((error) => {
                const errors = error.response?.data?.data?.errors;
                const errorValues = Object.keys(errors).map(function(key){
                    return errors[key];
                });
                const combinedErrors = errorValues.join(' | ');
                alert(combinedErrors);
                throw new Error(combinedErrors);
            });
    }


    /**
     * Toggle show hq modal
     *
     * @returns void
     */
    const toggleShowHqModal = (val: boolean): void => {
        if (val == state.showHqModal) return;
        state.showHqModal = val;
    };

    /**
     * Update the `currentTime` every five seconds
     */
    setInterval(() => state.currentTime = new Date(), 500);

    /**interval: 30 seconds */
    syncShopData();
    setInterval(() => syncShopData(), 30000);

    /**
     * Provide the store object for injection into individual components
     */
    provide('store', {
        state: readonly(state),
        pauseShopData,
        postPauseShopStatus,
        toggleCurbside,
        toggleShowHqModal
    } as Store);
};
