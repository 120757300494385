
import { defineComponent, inject } from 'vue';
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { createStore, Store } from '@/stores';
import Navigation from '@/components/Navigation/Navigation.vue';

export default defineComponent({
    name: 'App',

    components: {
        IonApp,
        IonRouterOutlet,
        Navigation,
    },

    setup() {
        createStore();
        const store = inject('store') as Store;
        return {
            store
        }
    },
});
