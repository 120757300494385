import { CacheProvider } from "./CacheService";

export class StateCache extends CacheProvider {
    protected allowedKeys: string[] = [
        'auth_token',
        'shop_id',
    ];

    public get AUTH_TOKEN(): string {
        return 'auth_token';
    }

    public get SHOP_ID(): string {
        return 'shop_id';
    }


    constructor() {
        super('state_cache');
    }

    public set(key: string, value: any): Promise<any> {
        if (!this.isAllowed(key)) return Promise.reject(false);
        return this.forage.setItem(key, value).catch(this.catchError.bind(this));
    }

    public get(key: string): Promise<any> {
        if (!this.isAllowed(key)) return Promise.reject(false);
        return this.forage.getItem(key).catch(this.catchError.bind(this));
    }

    public remove(key: string): Promise<any> {
        if (!this.isAllowed(key)) return Promise.reject(false);
        return this.forage.removeItem(key).catch(this.catchError.bind(this));
    }

    public clear(): Promise<any> {
        return this.forage.clear().catch(this.catchError.bind(this));
    }

    protected isAllowed(key: string): boolean {
        if (this.allowedKeys.indexOf(key) == -1) {
            throw `Config key ${key} is  not configurable!`;
        } else {
            return true;
        }
    }
}
