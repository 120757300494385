import { ApiCache, StateCache } from '@/services/cache';
import useConfig from '@/services/config';
import Axios, { AxiosRequestConfig } from 'axios';

export class XhrClient {
    protected cache = new ApiCache();
    protected state = new StateCache();

    public get baseUrl(): string {
        return useConfig().baseApiUrl();
    }

    public get(url: string, params?: any, header?: { [key: string]: any }): Promise<any> {
        return this.request('GET', url, params, header);
    }

    public post(url: string, params?: any, header?: { [key: string]: any }): Promise<any> {
        return this.request('POST', url, params, header);
    }

    public put(url: string, params?: any, header?: { [key: string]: any }): Promise<any> {
        return this.request('PUT', url, params, header);
    }

    public delete(url: string, params?: any, header?: { [key: string]: any }): Promise<any> {
        return this.request('DELETE', url, params, header);
    }

    /**
     *
     * @param method request method to use
     * @param url request url
     * @param params object used for query string or request body
     * @param header Header Overwrites, exp: checking shop details requires the cart endpoint to be called with a x-active-shop for that shop, but does not mean we should change in local storage
     */
    private async request(
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        url: string,
        params: { [key: string]: any },
        header?: { [key: string]: string | number }
    ): Promise<any> {
        const headers = await this.getheaders(header);
        const options: AxiosRequestConfig = {
            url,
            method,
            baseURL: this.baseUrl,
            headers,
        };

        // determine if data should be pass to body or search attribute
        if (method.includes('GET')) {
            options.params = params
        } else {
            options.data = params
        }

        return this.cache.fetchUrl(url, Axios(options));
    }

    // eslint-disable-line
    protected async getheaders(headerOverwrite: { [key: string]: any } = {}): Promise<any> {
        throw new Error(`define a getheaders method ${JSON.stringify(headerOverwrite)}`);
    }

    public getXhrHeaderClientKey() {
        return 'NextGenOnline'; //'TribeCurbsideOnline';
        // TODO, if we go native app route
        // const clientStr = 'TribeCurbsideOnline';
        // if (typeof cordova != 'undefined' && cordova.platformId == 'ios') {
        //     clientStr = 'TribeCurbsideiOS';
        // } else if (typeof cordova != 'undefined' && cordova.platformId == 'android') {
        //     clientStr = 'TribeCurbsideAndroid';
        // }
        // return clientStr;
    }
}
