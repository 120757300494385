import localForage from 'localforage';

export class CacheProvider {
    protected forage: LocalForage;

    protected get dbName() { return 'andpizza'; }

    constructor(storeName: string) {
        this.forage = localForage.createInstance({ name: this.dbName, storeName });
    }
    public set(key: string, value: any): Promise<any> {
        return this.forage.setItem(key, value).catch(this.catchError.bind(this));
    }

    public get(key: string): Promise<any> {
        return this.forage.getItem(key).catch(this.catchError.bind(this));
    }

    public remove(key: string): Promise<any> {
        return this.forage.removeItem(key).catch(this.catchError.bind(this));
    }

    public clear(): Promise<any> {
        return this.forage.clear().catch(this.catchError.bind(this));
    }

    protected catchError(e: any) {
        console.log('CacheProvider - catchError:', e);
        throw e;
    }
}
