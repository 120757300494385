import { CodePushConfigI, FeatureFlagsConfigI, FireBaseConfigI, ConfigI } from '.'

const config: ConfigI = {
    baseUrl: (): string => 'https://api-staging.andpizza.com',
    baseApiUrl: (): string => `${config.baseUrl()}/webapi/v100`,
    apiToken: (): string => 'SrM8gqYvLYOowhu0deSheJxCuWBX',
    mixpanelToken: (): string => '',
    cache: (): boolean => true,
    firebaseConfig: (): FireBaseConfigI => ({
        apiKey: 'AIzaSyAC65WPIK_ut4TvMDq5agnCFv6d4uv2vIk',
        authDomain: 'andpizza-nextgen.firebaseapp.com',
        databaseURL: 'https://andpizza-nextgen.firebaseio.com',
        projectId: 'andpizza-nextgen',
        storageBucket: 'andpizza-nextgen.appspot.com',
        messagingSenderId:'351865441376',
    }),
    firebaseDatabasePrefix: (): string => 'staging-pickup',
    firebaseMessagingDatabasePrefix: (): string => 'staging-conversations',
    authenticationUrl: (): string => 'https://kds-staging.andpizza.com/screens',
    rollbarAccessToken: (): string => '37259be4cbab472bb0199df18c9a30ea',
    codepush: (): CodePushConfigI => ({
        ios: () => '',
        android: () => '',
    }),
    featureFlags: (): FeatureFlagsConfigI => ({
        showOrderMessages: true,
        showHqMessages: true,
        showAlertBanner: false,
    }),
}

export default config;
