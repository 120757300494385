import { alertController, } from '@ionic/vue';
import { OverlayEventDetail, AlertOptions } from '@ionic/core';
import useConfigs from '@/services/config';
import './alert.css';

/**
 * Show an alert dialog with specified text & button
 * Returns a promise that resolves when the dialog is closed
 *
 * @param options
 * @return Promise
 */
export default async function showAlert(options: AlertOptions): Promise<OverlayEventDetail> {
    const alert = await alertController.create({
        cssClass: 'confirmation-alert',
        backdropDismiss: false,
        header: options.header ?? '',
        subHeader: options.subHeader ?? '',
        message: options.message ?? '',
        buttons: options.buttons,
    });
    alert.present();

    // Return a promise that resolves when the box is closed
    return alert.onDidDismiss();
}

/**
 * Special-case confirmation dialog for authentication errors
 */
export async function showAuthError(): Promise<void> {
    showAlert({
        message: 'Could not authenticate with the server. Please log in again.',
        buttons: ['Log In'],
    }).then(() => {
        window.location.href = useConfigs().authenticationUrl();
    });
}
