import { AxiosPromise, AxiosResponse } from 'axios';
import useConfig from '@/services/config';

import { CacheProvider } from "./CacheService";

export class ApiCache extends CacheProvider {
    // URls in this array will be skipped.
    // No cache will happen for non get request no need to add those
    private get GETurlsToExempt(): RegExp[] {
        return [
            /^\/user\/guest-token$/,
            /^\/cart\/?(item|validate)?$/, // skip cart endpoint that could change per request
            /^\/user\/logout$/,
            /^\/orders\/totals$/,
            /^\/nearby$/,
            /^\/pickup-times$/,
            /^\/loyalty\/status$/,
            /^\/delivery\/availability$/,
            /^\/marketplace$/,
            /^\/wallet$/,
            /^\/curbside\/firebase-token\/.*$/,
            /^\/curbside\/notifications\/.*$/,
            /^\/curbside\/notification\/.*$/,
            /^\/curbside\/conversation\/.*$/,
            /^\/curbside\/conversations\/.*$/,
            /^\/shops\/.*$/,
        ];
    }
    // first number represents minutes
    private get endpointTimeout(): number { return (5 * (60 * 1000)) }

    constructor() {
        super('api_cache');
    }

    async fetchUrl(url: string, xhrRequest: AxiosPromise<any>): Promise<any> {
        // cached version of endpoint found
        let resCache = await this.get(url);
        // endpoints are stored in base64 if possible
        resCache = typeof resCache == 'string' ? JSON.parse(atob(resCache)) : resCache;
        // cache is enabled
        const cacheEnabled = useConfig().cache();

        if (resCache && this.cachNotExpired(resCache.timestamp) && cacheEnabled) {
            console.log('ApiCache: Cache', url);
            return resCache.data;
        }

        console.log('ApiCache: XHR', url);

        return xhrRequest.then( this.cacheResponse.bind(this, url) );
        // return this.cacheResponse(url, method, res);
    }

    protected cachNotExpired(endpointStamp: number) {
        return (new Date().getTime() - endpointStamp) < this.endpointTimeout;
    }

    /**
     * Check if response should be cached
     * Only executes if config cache is true
     * loggic:
     * - method is GET,
     * - not in GETurlsToExempt array
     * - res object is defined (undefied if res code not 200)
     *
     * @param  {string} url    [description]
     * @param  {string} method [description]
     * @param  {any}    res    [description]
     * @return {any}           [description]
     */
    protected cacheResponse(url: string, {config, data}: AxiosResponse/* {config, data}: AxiosResponse */): any {
        // continue if cahce config is enabled
        if (!useConfig().cache()) return data;
        const methodIsGet: boolean = config.method == 'get';

        /**
         * Check if url matches any patterns of urls to skip,
         * accumulator is set to false and futher validation is skipped if accumulator is set to true at somepoint
         */
        const inExemptArr: boolean = this.GETurlsToExempt.reduce( (urlMatched: boolean, urlPattern: RegExp): boolean => urlMatched || urlPattern.test(url), false);
        // TODO, continue with endpoint caching
        if (data && methodIsGet && !inExemptArr) {
            const timestamp = new Date().getTime();
            const resData = { timestamp, data };
            try {
                const encodedData = btoa(JSON.stringify(data));
                this.set(url, encodedData);
            } catch (error) {
                this.set(url, resData);
            }
        }

        return data;
    }
}
