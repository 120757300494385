import { createApp } from 'vue';
import { IonicVue } from '@ionic/vue';
import router from '@/router';
import CookieAuth from '@/services/cookie-auth';
import Rollbar from '@/services/rollbar';
import App from './App.vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/fonts.css';
import './theme/variables.css';

const app = createApp(App)
    .use(IonicVue, {
        // Ionic configs
        swipeBackEnabled: false,
        mode: 'ios',
        iconMode: 'ios',
        // @TODO, evaluate if we need this, else use https://animejs.com/
        animated: true,
    })
    .use(CookieAuth)
    .use(router)
    .use(Rollbar);

router.isReady().then(() => {
    app.mount('#app');
});
